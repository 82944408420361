@use "variables";

#testimonial {
  max-width: 700px;
  width: 100%;
  background-color: rgba(137, 43, 226, 0.407);
}
// #testimonial {
//   min-height: 800px;
//   display: flex;
//   justify-content: center;p
//   align-items: center;

//   .container {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     flex-direction: column;

//     h2 {
//       user-select: none;
//       padding-top: 40px;
//       font-size: 32px;
//       color: variables.$light001;
//       margin-bottom: 70px;
//     }

//     .testimonialList {
//       display: flex;
//       flex-direction: row;
//       gap: 20px;
//     }
//     .testimonialListItem {
//       .testimonialCard {
//         background-color: variables.$dark002;
//         border-radius: 7px;
//         max-width: 340px;
//         min-height: 230px;
//         flex: 0 0 calc(33.33% - 90px); /* Distribute cards evenly with margin */
//         margin: 10px;
//         padding: 20px;
//         transition: transform 0.2s ease;

//         .feedbackPara {
//           color: variables.$light006;
//           margin-top: 30px;
//         }
//         .userNameTestimonial {
//           color: variables.$light001;
//         }
//         .designationTestimonial {
//           color: variables.$light002;
//           font-weight: 300;
//         }

//         .feedbackUserContainer {
//           position: relative;
//         }

//         .feedbackuser {
//           position: absolute;
//           top: -60px;
//           img {
//             width: 70px;
//             height: 70px;
//           }
//         }

//         .feddbackUserDetails {
//           margin-top: 20px;
//           display: flex;
//           flex-direction: row;
//           justify-content: space-between;
//           align-items: center;
//         }
//       }

//       .testimonialCard:hover {
//         background-color: variables.$dark003;
//         transform: translate(-10px, -20px);
//       }
//     }
//     img {
//       height: 20px;
//       width: 20px;
//     }
//   }
// }
